import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"

export const pageQuery = graphql`
  query StaticPageComponentQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        title
      }
      frontmatter {
        title
        cover_filepath
        image_filepath
      }
      headings {
        value
        depth
      }
    }
  }
`

const StaticComponent = (props) => (
  <Layout>
    {/* <h1>{props.data.markdownRemark.frontmatter.title}</h1> */}
    <div className="textcontent" dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html || 'x'}} />
  </Layout>
)

export default StaticComponent
